import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import MainContainer from "../components/containers/MainContainer"
import HeadDataNew from "../data/HeadDataNew"
import ContactSection from "../components/sections/ContactSection";

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactPageQuery {
      prismicContactPage {
        data {
          meta_title
          meta_description
        }
      }
    }
  `)

  const doc = data.prismicContactPage.data

  return (
    <MainContainer>
      <HeadDataNew 
        title={doc.meta_title}
        description={doc.meta_description}
      />

      <ContactSection />
    </MainContainer>
  )
}

export default ContactPage
