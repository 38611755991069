import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HubspotForm from 'react-hubspot-form'

const innerDiv = 'bg-white rounded-md shadow-xs p-6 mx-0 mb-4 md:mb-8 shadow-lg'
const contactList = 'text-gray-900 mb-6'
const contactWrapper = 'flex flex-col lg:flex-row items-start mb-3'
const contactPhoto = 'w-32 md:w-24 rounded mb-2 md:mr-4'

const ContactSection = () => {
  const data = useStaticQuery(graphql`
    query ContactSectionQuery {
      prismicContactPage {
        data {
          business_hours
          contacts_section {
            email
            name
            phone
            team_photo {
              gatsbyImageData(width: 500)
            }
            position
          }
          east_coast_stock_listing_file {
            link_type
            url
          }
          google_maps_link
          office_address {
            address
            location
          }
          page_title
          phone_numbers {
            label
            phone
          }
        }
      }
    }
  `)

  const doc = data.prismicContactPage.data
  const team = doc.contacts_section
  const phones = doc.phone_numbers
  const offices = doc.office_address

  return (
    <div className='bg-white pt-12 pb-6'>
      <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto mb-8'>
        <h1 className='font-display text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>{doc.page_title ? doc.page_title : "Page Title"}</h1>
      </div>

      <div className='w-11/12 lg:w-10/12 xl:w-8/12 xl:max-w-screen-lg flex flex-col md:flex-row mx-auto'>
        <div className='w-full md:w-1/2 md:mr-3'>
          <div className={innerDiv}>
            <h2 className='font-display text-3xl text-red-900 sm:leading-none mb-4 tracking-wide'>Contacts</h2>

            {team.map(item => (
              <div className={contactWrapper}>
                <GatsbyImage
                  image={getImage(item.team_photo)}
                  alt={item.name ? item.name : ""}
                  className={contactPhoto}
                />
                <p className={contactList}><span className='font-bold text-xl'>{item.name ? item.name : "Name"}</span><br className={item.position ? "visible" : "hidden"} />{item.position ? item.position : null}<br/>
                {item.email}<br/>
                {item.phone}</p>
              </div>
            ))}
          </div>

          <div className={innerDiv}>
            <h2 className='font-display text-3xl text-red-900 mb-4 tracking-wide'>Phone Numbers</h2>

            {phones.map(item => (
              <>
                <p className='text-sm font-semibold text-gray-800'>{item.label}</p>
                <p className='text-3xl font-bold text-gray-900 mb-3'>{item.phone}</p>
              </>
            ))}
          </div>

          <div className={innerDiv}>
            <h2 className='font-display text-3xl text-red-900 mb-4 tracking-wide'>Business Hours</h2>
            <p className='text-xl font-bold text-gray-900'>{doc.business_hours}</p>
          </div>
        </div>

        <div className='w-full md:w-1/2 md:ml-3'>
          <div className={innerDiv}>
            <h2 className='font-display text-3xl text-red-900 mb-4 md:mb-6 tracking-wide'>Have a question for us?</h2>

            <HubspotForm
              portalId='9298297'
              formId='75c1c799-4ff7-41d5-a2ac-e5e67b097a7d'
              loading={<div className='italic'>Loading contact form...</div>}
            />
          </div>

          <div className={innerDiv}>
            <h2 className='font-display text-3xl text-red-900 mb-4 tracking-wide'>Office Address</h2>

            {offices.map(item => (
              <p className='mb-6'><b>{item.location}</b><br/>{item.address}</p>
            ))}

            {doc.east_coast_stock_listing_file.link_type === "Document" || doc.east_coast_stock_listing_file.link_type === "Any"
              ?
                <Link to={doc.east_coast_stock_listing_file.uid ? `/${doc.east_coast_stock_listing_file.uid}` : "/"}>
                  <StaticImage
                    src="../../images/sections/Stock-Listing-East-Coast-Warehouse.png"
                    width={910}
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt=""
                    className='w-10/12 mt-3'
                  />
                </Link>
              :
              doc.east_coast_stock_listing_file.link_type === "Web" || doc.east_coast_stock_listing_file.link_type === "Media"
              ?
                <a
                  href={doc.east_coast_stock_listing_file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StaticImage
                    src="../../images/sections/Stock-Listing-East-Coast-Warehouse.png"
                    width={910}
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt=""
                    className='w-10/12 mt-3'
                  />
                </a>
              :
              null
            }
          </div>

          <div className={innerDiv}>
            <h2 className='font-display text-3xl text-red-900 mb-4 md:mb-6 tracking-wide'>Map Location</h2>
            <a href={doc.google_maps_link} target='_blank' rel='noopener noreferrer'>
              <StaticImage
                src="../../images/pages/command-access-google-map.png"
                width={598}
                quality={95}
                formats={["AUTO", "WEBP"]}
                alt="Command Access Google Map location"
                className='w-full'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactSection